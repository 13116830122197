<template>
    <p>
        Copyright © {{ new Date().getFullYear() }}
        {{$t('JiaJing Tech')}}
        <span class="d-none d-sm-inline-block">{{$t('All rights Reserved')}}</span>
        {{beiAnNo}}
    </p>
</template>
<script>
export default {
    name: 'copyright',
    computed: {
		beiAnNo () {
			let host = window.location.host
			let temp = host.split('.')
			let type = temp[temp.length-1]
			if(type=='com'){
				return '鲁ICP备18003666号-17'
			}else{
				return '鲁ICP备18003666号-18'
			}
		}
	},
}
</script>