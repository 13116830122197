<template>
	<main class="page-home">
		<div class="home-nav" :style="{'background-color': 'rgba(245, 242, 252, '+opacity+')'}">
			<div class="container d-flex align-items-center">
				<b-link class="brand-logo" to="/">
					<img class="img" src="@/assets/images/logo/logo.png" alt="">
					<h2 class="brand-text text-primary ml-1">FruitScreen</h2>
				</b-link>
				<div class="d-none d-lg-flex align-items-center ml-auto">
					<ul class="nav d-flex align-items-center">
						<li class="nav-item px-1 px-md-2" @click="goAnthor('#product')">{{$t('Product Center')}}</li>
						<li class="nav-item px-1 px-md-2" @click="goAnthor('#about')">{{$t('About Us')}}</li>
						<li class="nav-item px-1 px-md-2" @click="goAnthor('#download')">{{$t('Client Download')}}</li>
						<li class="nav-item px-1 px-md-2" @click="goAnthor('#contact')">{{$t('Contact Us')}}</li>
						<locale class="mr-2"></locale>
						<b-nav-item-dropdown
							right
							toggle-class="d-flex align-items-center dropdown-user-link"
							class="dropdown-user"
							v-if="userInfo"
						>
							<template #button-content>
								<div class="d-sm-flex d-none user-nav">
									<p class="user-name font-weight-bolder mb-0 mr-25">
										{{userInfo.nickname}}
									</p>
									<!-- <span class="user-status">Admin</span> -->
								</div>
								<b-avatar
									size="30"
									variant="light-primary"
									:src="userInfo.avatar || $config.defaultAvatar"
									class="badge-minimal"
									badge-variant="success"
								/>
							</template>
							<b-dropdown-item link-class="d-flex align-items-center" @click="$router.push('/admin')">
								<feather-icon
									size="16"
									icon="HomeIcon"
									class="mr-50"
								/>
								<span>{{$t('Dashboard')}}</span>
							</b-dropdown-item>
							<b-dropdown-item link-class="d-flex align-items-center" @click="logoutAction">
								<feather-icon
									size="16"
									icon="LogOutIcon"
									class="mr-50"
								/>
								<span>{{$t('Logout')}}</span>
							</b-dropdown-item>
						</b-nav-item-dropdown>
					</ul>
					
					<b-button variant="primary" @click="gotoLogin" v-if="!userInfo">{{$t('Log In')}}</b-button>
				</div>
				<locale class=" d-flex d-lg-none ml-auto"></locale>
				<b-nav-item-dropdown
					right
        			toggle-class="dropdown-home-menu-link"
					class=" d-flex d-lg-none ml-1"
				>
					<template #button-content>
						<feather-icon size="16" icon="MenuIcon" />
					</template>

					<b-dropdown-item @click="goAnthor('#product')">{{$t('Product Center')}}</b-dropdown-item>
					<b-dropdown-item @click="goAnthor('#about')">{{$t('About Us')}}</b-dropdown-item>
					<b-dropdown-item @click="goAnthor('#download')">{{$t('Client Download')}}</b-dropdown-item>
					<b-dropdown-item @click="goAnthor('#contact')">{{$t('Contact Us')}}</b-dropdown-item>
					<b-dropdown-item>
						<b-button variant="primary">登录</b-button>
					</b-dropdown-item>
				</b-nav-item-dropdown>
			</div>
		</div>
		<!-- section1 -->
		<section class="secion-home d-flex align-items-center" id="home">
			<div class="bg-holder"></div>
			<div class="bg-holder d-none d-xl-block hero-bg  wow animate__animated animate__fadeIn" data-wow-duration="1s"></div>
			<div class="container">
				<div class="row align-items-center h-100 justify-content-center justify-content-lg-start">
					<div class="col-md-9 col-xl-5 text-md-left text-center wow animate__animated animate__fadeInUp">
						<h1 class="text-dark">FruitScreen</h1>
						<p class="text-black-50 mt-2">{{$t('slogan')}}</p>
						<div class="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start mt-5">
							<b-button variant="primary" @click="goAnthor('#product')">{{$t('start')}} <feather-icon icon="ArrowRightIcon" size="16" /></b-button>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- 产品 -->
		<section id="product" class="section-product my-5">
            <div class="container">
				<h1 class="section-title py-5  wow animate__animated animate__zoomIn">{{$t('Product Center')}}</h1>
				<swiper
					class="swiper-coverflow wow animate__animated animate__fadeInUp"
					:options="productSwiperOptions"
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
				>
					<swiper-slide
						v-for="(item,index) in productList"
						:key="index"
						class="product-slide"
					>
						<div class="home-product text-center my-3">
                            <div class="img">
                                <img src="@/assets/images/home/temp-product.svg" alt="">
                            </div> 
							<h4 class="mt-5 text-primary">{{item.name}}</h4>
							<b-row class="mt-1">
								<b-col :cols="12">{{item.desc}}</b-col>
								<b-col :cols="12">{{$t('Version')}}：{{item.version}}</b-col>
							</b-row>
							<b-button variant="primary" size="sm" class="mt-2" @click="downloadApp(item)">{{index<2 ? $t('Download') : $t('Access')}}</b-button>
							<!-- <b-row>
								<b-col :cols="12">屏幕尺寸：3*20</b-col>
								<b-col :cols="12">分辨率：1920*1080</b-col>
							</b-row> -->
                        </div>
					</swiper-slide>
					<div
						slot="pagination"
						class="swiper-pagination"
					/>
				</swiper>
            </div>
        </section>
		<!-- {{$t('About Us')}} -->
		<section id="about" class="light-pruple section-about py-5 my-5">
            <div class="container">
				<h1 class="section-title py-5 wow animate__animated animate__zoomIn ">{{$t('About Us')}}</h1>
				<b-row class="align-items-center pb-5">
					<b-col :cols="12" :md="6" class=" wow animate__animated animate__fadeInLeft">
						<swiper
							class="about-swiper"
							:options="swiperOptions"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						>
							<swiper-slide
								v-for="(data,index) in swiperData"
								:key="index"
							>
								<b-img :src="data.img"  />
							</swiper-slide>
							<div
								slot="pagination"
								class="swiper-pagination swiper-pagination-white"
							/>
						</swiper>
					</b-col>
					<b-col :cols="12" :md="6" class=" mt-3 mt-md-0 pl-md-5 wow animate__animated animate__fadeInRight">
						<p>{{$t('homeAbout1')}}</p>
						<p class="mt-2">{{$t('homeAbout2')}}</p>
						<p class="mt-2">
							<feather-icon size="20" class="text-primary" icon="ArrowRightIcon" />
							{{$t('homeAbout3')}}
						</p>
						<p>
							<feather-icon size="20" class="text-primary" icon="ArrowRightIcon" />
							<!-- <span class="bullet bullet-sm mr-1 bullet-primary"/> -->
							{{$t('homeAbout4')}}
						</p>
						<p>
							<feather-icon size="20" class="text-primary" icon="ArrowRightIcon" />
							<!-- <span class="bullet bullet-sm mr-1 bullet-primary"/> -->
							{{$t('homeAbout5')}}
						</p>
					</b-col>
				</b-row>
            </div>
        </section>
		<!-- {{$t('Client Download')}} -->
		<section id="download" class="section-download py-5 my-5">
            <div class="container">
				<b-row class="align-items-center">
					<b-col :cols="12" :md="6">
						<h1 class="section-title pb-5 wow animate__animated animate__zoomIn">{{$t('Client Download')}}</h1>
						<b-row class="align-items-center text-center">
							<b-col :cols="12" :md="6" class=" wow animate__animated animate__fadeInUp">
								<img class="download-code shadow" src="@/assets/images/home/temp-code.png" alt="">
								<h4 class="mt-1">{{$t('android')}}</h4>
							</b-col>
							<b-col :cols="12" :md="6" class=" wow animate__animated animate__fadeInUp">
								<img class="download-code shadow" src="@/assets/images/home/temp-code.png" alt="">
								<h4 class="mt-1">{{$t('tv')}}</h4>
							</b-col>
						</b-row>
					</b-col>
					<b-col :cols="12" :md="5" :offset-md="1">
						<img class="w-100 wow animate__animated animate__fadeInRight" src="@/assets/images/home/img-app.png" :alt="$t('Client Download')">
					</b-col>
				</b-row>
            </div>
        </section>
		<!-- 联系我们 -->
		<section id="contact" class="section-contact light-pruple py-5">
            <div class="container">
				<b-row class="align-items-center py-5">
					<b-col :cols="12" :md="5">
						<img class="w-100 wow mt-3 mt-md-0 animate__animated animate__fadeInLeft" src="@/assets/images/home/img-contact.png" :alt="$t('Contact Us')">
					</b-col>
					<b-col :cols="12" :md="7">
						<h1 class="section-title pb-5 mt-3 mt-md-0 wow animate__animated animate__zoomIn">{{$t('Contact Us')}}</h1>
						<b-form @submit.prevent>
							<b-row class="align-items-center">
								<b-col :cols="12" :md="6">
									<b-form-group
										:label="$t('UserName')"
										label-for="vi-first-name"
									>
										<b-input-group class="input-group-merge">
											<b-input-group-prepend is-text>
												<feather-icon icon="UserIcon" />
											</b-input-group-prepend>
											<b-form-input
												id="vi-first-name"
												:placeholder="$t('Please Enter...')"
											/>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col :cols="12" :md="6">
									<b-form-group
										:label="$t('Email')"
										label-for="vi-first-name"
									>
										<b-input-group class="input-group-merge">
											<b-input-group-prepend is-text>
												<feather-icon icon="MailIcon" />
											</b-input-group-prepend>
											<b-form-input
												id="vi-first-name"
												:placeholder="$t('Please Enter...')"
											/>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col :cols="12" :md="6">
									<b-form-group
										:label="$t('Phone')"
										label-for="vi-first-name"
									>
										<b-input-group class="input-group-merge">
											<b-input-group-prepend is-text>
												<feather-icon icon="PhoneIcon" />
											</b-input-group-prepend>
											<b-form-input
												id="vi-first-name"
												:placeholder="$t('Please Enter...')"
											/>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col :cols="12" :md="6">
									<b-form-group
										:label="$t('Company Name')"
										label-for="vi-first-name"
									>
										<b-input-group class="input-group-merge">
											<b-input-group-prepend is-text>
												<feather-icon icon="BookmarkIcon" />
											</b-input-group-prepend>
											<b-form-input
												id="vi-first-name"
												:placeholder="$t('Please Enter...')"
											/>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col :cols="12">
									<b-form-group
										:label="$t('Message')"
										label-for="msg"
									>
										<b-form-textarea
											id="msg"
											:placeholder="$t('Please Enter...')"
											rows="8"
										/>
									</b-form-group>
								</b-col>
								<b-col :cols="12" class="text-center">
									<b-button class="mr-5" variant="primary">{{$t('Submit')}}</b-button>
									<b-button variant="outline-secondary">{{$t('Reset')}}</b-button>
								</b-col>
							</b-row>
						</b-form>
						<!-- <b-row>
							<b-col :cols="12" :md="6">
								<div class="contact-item wow animate__animated animate__zoomIn">
									<div class="icon"><feather-icon size="20" icon="PhoneCallIcon" /></div>
									<dl class="info">
										<dt>全国免费咨询热线</dt>
										<dd>400-8836-968</dd>
									</dl>	
								</div>
							</b-col>
							<b-col :cols="12" :md="6">
								<div class="contact-item wow animate__animated animate__zoomIn">
									<div class="icon"><feather-icon size="20" icon="PrinterIcon" /></div>
									<dl class="info">
										<dt>公司传真</dt>
										<dd>0731-86183597</dd>
									</dl>	
								</div>
							</b-col>
							<b-col :cols="12" :md="6">
								<div class="contact-item wow animate__animated animate__zoomIn">
									<div class="icon"><feather-icon size="20" icon="MailIcon" /></div>
									<dl class="info">
										<dt>公司邮箱</dt>
										<dd>fruitScreen@fruitScreen.com</dd>
									</dl>	
								</div>
							</b-col>
							<b-col :cols="12" :md="6">
								<div class="contact-item wow animate__animated animate__zoomIn">
									<div class="icon"><feather-icon size="20" icon="MapPinIcon" /></div>
									<dl class="info">
										<dt>公司地址</dt>
										<dd>山东省济南市高新区舜泰广场</dd>
									</dl>	
								</div>
							</b-col>
						</b-row> -->
					</b-col>
				</b-row>
            </div>
        </section>
		<!-- 联系我们 -->
		<section id="feedback" class="section-feedback py-5 my-5 d-none">
            <div class="container wow animate__animated animate__fadeInUp">
				<b-form @submit.prevent>
					<b-row class="align-items-center">
						<b-col :cols="12" :md="6">
							<b-form-group
								label="姓名"
								label-for="vi-first-name"
							>
								<b-input-group class="input-group-merge">
									<b-input-group-prepend is-text>
										<feather-icon icon="UserIcon" />
									</b-input-group-prepend>
									<b-form-input
										id="vi-first-name"
										placeholder="请输入您的姓名"
									/>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col :cols="12" :md="6">
							<b-form-group
								label="邮箱"
								label-for="vi-first-name"
							>
								<b-input-group class="input-group-merge">
									<b-input-group-prepend is-text>
										<feather-icon icon="MailIcon" />
									</b-input-group-prepend>
									<b-form-input
										id="vi-first-name"
										placeholder="请输入您的邮箱"
									/>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col :cols="12" :md="6">
							<b-form-group
								label="联系电话"
								label-for="vi-first-name"
							>
								<b-input-group class="input-group-merge">
									<b-input-group-prepend is-text>
										<feather-icon icon="PhoneIcon" />
									</b-input-group-prepend>
									<b-form-input
										id="vi-first-name"
										placeholder="请输入您的联系电话"
									/>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col :cols="12" :md="6">
							<b-form-group
								label="公司名称"
								label-for="vi-first-name"
							>
								<b-input-group class="input-group-merge">
									<b-input-group-prepend is-text>
										<feather-icon icon="BookmarkIcon" />
									</b-input-group-prepend>
									<b-form-input
										id="vi-first-name"
										placeholder="请输入您的公司名称"
									/>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col :cols="12">
							<b-form-group
								label="留言"
								label-for="msg"
							>
								<b-form-textarea
									id="msg"
									placeholder="请输入您的留言"
									rows="8"
								/>
							</b-form-group>
						</b-col>
						<b-col :cols="12" class="text-center">
							<b-button class="mr-5" variant="primary">提交</b-button>
							<b-button variant="outline-secondary">重置</b-button>
						</b-col>
					</b-row>
				</b-form>
			</div>
		</section>
		<!-- 底部 -->
		<footer class="home-footer">
			<div class="container py-5">
				<b-row>
					<b-col :cols="12" :md="6">
						<b-link class="brand-logo mt-0 mt-md-4" to="/">
							<img class="img" src="@/assets/images/logo/logo.png" alt="">
							<h2 class="brand-text text-white ml-1">FruitScreen</h2>
						</b-link>
						<!-- <p class="mt-1">全国免费咨询热线</p>
						<p class="tel text-primary">400-8836-968</p>
						<p>公司传真：0531-86183597</p> -->
					</b-col>
					<b-col :cols="6" :md="2">
						<dl>
							<dt class="mt-2 mt-md-0">{{$t('Shortcuts')}}</dt>
							<dd @click="goAnthor('#product')">{{$t('Product Center')}}</dd>
							<dd @click="goAnthor('#about')">{{$t('About Us')}}</dd>
							<dd @click="goAnthor('#download')">{{$t('Client Download')}}</dd>
							<dd @click="goAnthor('#about')">{{$t('Contact Us')}}</dd>
						</dl>
					</b-col>
					<b-col :cols="6" :md="2">
						<dl>
							<dt class="mt-2 mt-md-0">{{$t('Disclaimer and Authorization')}}</dt>
							<dd><b-link>{{$t('User agreement')}}</b-link></dd>
							<dd><b-link>{{$t('Privacy policy')}}</b-link></dd>
						</dl>
					</b-col>
					<b-col :cols="12" :md="2">
						<dl class="text-center">
							<dt class="mt-2 mt-md-0">{{$t('Client Download')}}</dt>
							<dd>
								<img class="download-code" src="@/assets/images/home/temp-code.png" alt="">
							</dd>
						</dl>
					</b-col>
				</b-row>
				
			</div>
			<div class="copyright text-center">
				<copyright />
			</div>
		</footer>
	</main>
</template>

<script>
import { 
	BCard, 
	BCardText, 
	BLink, 
	BBadge,
	BImg, 
	BNavbar,
	BNavbarNav, 
	BNavbarToggle, 
	BCollapse, 
	BTabs, 
	BNavItem, 
	BButton,
	BNavItemDropdown,
	BDropdownItem,
	BAvatar,
	BRow,
	BCol,
	BForm,
	BFormGroup,
	BInputGroup,
	BInputGroupPrepend,
	BFormInput,
	BFormTextarea,
} from 'bootstrap-vue'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import 'animate.css/animate.min.css'
import { WOW } from 'wowjs'
import locale from '@/layouts/components/Locale.vue'
import copyright from '@/components/public/Copyright.vue'
import {mapState} from 'vuex'
export default {
	components: {
		BCard,
		BCardText,
		BLink,
		BBadge,
		BImg, 
		BNavbar, BNavbarNav, BNavbarToggle, BNavItem,
		BCollapse, 
		BTabs, 
		BButton,
		BNavItemDropdown,BDropdownItem,
		BAvatar,
		BRow,BCol,
		Swiper,
    	SwiperSlide,
		BForm,
		BFormGroup,
		BInputGroup,
		BInputGroupPrepend,
		BFormInput,
		BFormTextarea,
		locale,
		copyright
	},
	data () {
		return {
			productList: [{
				name: 'fruitscreen tv版',
				desc: '屏幕端',
				version: '1.0.0.0',
				url:''
			},{
				name: 'fruitscreen android版',
				desc: '安卓手机端',
				version: '1.0.0.0',
				url: ''
			},{
				name: 'fruitscreen web版',
				desc: '网页端',
				version: '1.0.0.0',
				url: ''
			}],
			productSwiperOptions: {
				slidesPerView: 3,
				spaceBetween: 20,
				loop:false,
				autoplay : false,
            
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				breakpoints: {
					1920: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 10,
					},
					640: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
					320: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
				},
			},
			swiperData: [
				{ img: require('@/assets/images/home/temp-banner.jpg') },
				{ img: require('@/assets/images/home/temp-banner.jpg') },
				{ img: require('@/assets/images/home/temp-banner.jpg') },
				{ img: require('@/assets/images/home/temp-banner.jpg') },
				{ img: require('@/assets/images/home/temp-banner.jpg') },
			],
			swiperOptions: {
				pagination: {
					el: '.swiper-pagination',
				},
			},
			opacity: 0
		}
	},
	computed: {
		...mapState({
			userInfo: state=>state.app.userInfo
		})
	},
	mounted () {
		this.$nextTick(()=>{
			new WOW({
				live: false, 
                offset: 0
			}).init()
		})
		
		window.addEventListener('scroll',this.onScroll)
	},
	beforeDestroy () {
		window.removeEventListener('scroll',this.onScroll)
	},
	methods: {
		onScroll (e) {
			var top = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
			this.opacity = Math.min(1, top/120)
		},
		goAnthor (selector) {
      		const height = document.querySelector(selector).offsetTop
			const container = document.documentElement
			container.scrollTo({
				top: height-50,
				behavior: 'smooth'
			})
		},
		scrollTo () {
			console.log(document.getElementById("#feedback"))
			document.getElementById("#feedback").scrollIntoView();
		},
		gotoLogin () {
			this.$router.push('/login')
		},
		downloadApp (item) {
			this.gotoLogin()
			// window.open(item.url)
		}
	}
}
</script>

<style lang="scss" >
@import '../assets/scss/home.scss';
</style>
